<template>
  <v-container class="fill-height" fluid>
    <v-row v-if="!isDirectView || !isMobile" class="">
      <v-col cols="12" id="calendar-view">
        <v-sheet height="64" class="mt-n3" color="transparent" id="toolbar">
          <v-toolbar flat>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small color="black">mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small color="black">mdi-chevron-right</v-icon>
            </v-btn>
            <v-toolbar-title color="black" v-html="title"></v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="calc(100% - 64px)">
          <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="weekday"
            :interval-height="48"
            color="primary"
            locale="fr"
            :type="type"
            :interval-style="styleInterval"
            :event-name="eventName"
            :events="allEvents"
            :event-color="getEventColor"
            :short-weekdays="false"
            @change="updateRange"
            :first-interval="7"
            :interval-count="15"
            :interval-format="intervalFormat"
          ></v-calendar>
        </v-sheet>
      </v-col>
      <v-col
        cols="12"
        style="position: absolute; z-index: 2; bottom: 0"
        class="pb-0"
      >
        <v-row class="d-flex justify-start align-center">
          <v-col cols="1" class="d-flex justify-start align-center">
            <qrcode-vue
              :value="directionQrCode('prev')"
              level="L"
              v-if="!isDirectView"
            ></qrcode-vue>
            <v-btn fab large color="primary" @click="prev">
              <v-icon large color="black">mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1" offset="1" class="d-flex justify-start align-center">
            <v-btn fab large color="primary" @click="next">
              <v-icon large color="black">mdi-chevron-right</v-icon>
            </v-btn>
            <qrcode-vue
              :value="directionQrCode('next')"
              level="L"
              v-if="!isDirectView"
            ></qrcode-vue>
          </v-col>
          <v-col cols="3" offset="6" class="d-flex justify-end align-center">
            <p class="mr-4">
              <v-btn :href="redirectLink" target="_blank">
                Réserver un équipement
              </v-btn>
            </p>
            <qrcode-vue :value="qrCodeUrl" level="L"></qrcode-vue>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else class="">
      <v-col cols="12" id="calendar-view">
        <v-sheet height="64" class="mt-n3" color="transparent" id="toolbar">
          <v-toolbar flat>
            <v-btn-toggle v-model="toggle_type" color="primary">
              <v-btn small>
                M/J
              </v-btn>
            </v-btn-toggle>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small color="primary">mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small color="primary">mdi-chevron-right</v-icon>
            </v-btn>
            <v-spacer>
              <v-btn :href="redirectLink" small target="_blank" color="primary">
                Réserver
              </v-btn>
            </v-spacer>
            <v-toolbar-title
              color="black"
              class="d-flex align-baseline small"
              v-html="smalltitle"
            >
            </v-toolbar-title>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="calc(100% - 64px)">
          <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="weekdayDirect"
            :interval-height="22"
            :event-height="25"
            color="primary"
            locale="fr"
            :type="types[toggle_type]"
            :interval-style="styleInterval"
            :event-name="eventName"
            :events="allEvents"
            :event-color="getEventColor"
            :short-weekdays="true"
            @change="updateRange"
            :first-interval="7"
            :interval-count="15"
            :interval-format="intervalFormat"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewWeek"
          ></v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TICKET_API from "../../api/ticket";
import moment from "../../plugins/moment";
import { capitalize, escapeHTML, shouldDisplay } from "../../utils";
import { mapGetters } from "vuex";
import QrcodeVue from "qrcode.vue";
import { convert } from "../../utils/icsToJson";
import { PORTAL_CONFIG } from "../../config";
import { EventBus } from "../../event-bus";

export default {
  name: "Calendar",
  components: {
    QrcodeVue
  },
  data: () => ({
    toggle_type: 2,
    types: ["day", "week", "month"],
    type: "week",
    reservations: [],
    reservationItems: [],
    value: "",
    start: null,
    today: moment().format(),
    end: null,
    weekday: [1, 2, 3, 4, 5, 6, 0],
    weekdayDirect: [1, 2, 3, 4, 5],
    events: [],
    icsEvents: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "deep-orange",
      "red lighten-1",
      "purple darken-1",
      "pink darken-1",
      "red accent-3",
      "deep-purple lighten-4",
      "indigo darken-1",
      "blue darken-4"
    ],
    icsColors: [
      "blue-grey darken-3",
      "grey darken-4",
      "grey darken-1",
      "grey darken-2",
      "grey",
      "blue-grey lighten-4",
      "blue-grey lighten-3",
      "blue-grey lighten-2",
      "blue-grey lighten-1",
      "grey darken-3"
    ],
    icsCalendarUid: []
  }),
  methods: {
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getEventColor(event) {
      return event.color;
    },
    formatDate(a, withTime = true) {
      return withTime
        ? `${a.getFullYear()}-${a.getMonth() +
            1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },
    loadData() {
      if (!this.safariAgent) {
        TICKET_API.getReservationItems().then(({ data }) => {
          const totalColors = this.colors.length;
          let reservationItems = data;
          TICKET_API.getReservations().then(({ data }) => {
            reservationItems.forEach(async (item, i) => {
              let reservations = data.filter(
                el => el.reservationitems_id === item.id
              );
              const {
                data: { name }
              } = await TICKET_API.getReservationItem(
                item.itemtype,
                item.items_id
              );
              reservations.forEach(res => {
                //if (this.events.length < 15) {
                if (shouldDisplay(res)) {
                  this.events.push({
                    name: `${name} - ${JSON.parse(res.comment).comment}`,
                    start: moment(res.begin).format("YYYY-MM-DD HH:mm:ss"),
                    end: moment(res.end).format("YYYY-MM-DD HH:mm:ss"),
                    color: this.colors[i % totalColors]
                  });
                }

                //}
              });
              this.$refs.calendar.checkChange();
              this.reservations = this.reservations.concat(reservations);
            });
          });
        });
      }
    },
    updateRange({ start, end }) {
      this.start = start;
      this.end = end;
    },
    styleInterval() {
      return {
        display: "flex",
        "flex-wrap": "wrap"
      };
    },
    eventName(event, timedEvent) {
      const name = escapeHTML(event.input["name"]);
      if (event.start.hasTime) {
        if (timedEvent) {
          const showStart = event.start.hour < 24 && event.end.hour >= 24;
          const start = this.formatTime(event.start, showStart);
          const end = this.formatTime(event.end, true);
          return `<strong>${name}</strong><br>${start} - ${end}`;
        } else {
          const time = this.formatTime(event.start, true);
          return `<strong class="mr-1">${time}</strong> ${name}`;
        }
      }
      return name;
    },
    intervalFormat(timestamp, short) {
      const suffix = short ? "h " : "";
      return `${timestamp.hour} ${suffix}`;
    },
    formatTime(withTime, ampm) {
      const suffix = ampm ? "h " : "";
      const hour = withTime.hour % 24 || 0;
      const minute = withTime.minute;

      return minute > 0
        ? minute < 10
          ? `${hour}:0${minute}${suffix}`
          : `${hour}:${minute}${suffix}`
        : `${hour}${suffix}`;
    },
    loadCalendar() {
      this.calendarUrl.forEach(url => {
        fetch(url, {
          headers: {
            "Content-Type": "text/calendar; charset=utf-8"
          }
        })
          .then(response => {
            return response.text();
          })
          .then(data => {
            let icsCalendar = convert(data);

            if (icsCalendar && icsCalendar.VCALENDAR) {
              const calendar = icsCalendar.VCALENDAR[0];
              const totalColors = this.icsColors.length;
              if (calendar.VEVENT) {
                calendar.VEVENT.forEach(res => {
                  if (!this.icsCalendarUid.includes(res.UID))
                    this.icsCalendarUid.push(res.UID);
                  this.icsEvents.push({
                    name: res.DESCRIPTION,
                    start: moment(res.DTSTART).format("YYYY-MM-DD HH:mm"),
                    end: moment(res.DTEND).format("YYYY-MM-DD HH:mm"),
                    color: this.icsColors[
                      this.getIcsColor(res.UID) % totalColors
                    ]
                  });
                });
              }
            }
          });
      });
    },
    directionQrCode(direction) {
      return `${PORTAL_CONFIG.SERVERLESS_URL}/calendar?direction=${direction}&uuid=${this.uniqID}`;
    },
    getIcsColor(Uid) {
      return this.icsCalendarUid.findIndex(c => c === Uid);
    },
    viewDay({ date }) {
      this.toggle_type = 0;
      this.value = date;
    },
    viewWeek({ date }) {
      this.toggle_type = 1;
      this.value = date;
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    }
  },

  mounted() {
    if (this.getUserProfileStatus) {
      this.loadData();
    }

    if (this.getPersonalToken) {
      this.loadCalendar();
    }

    EventBus.$on("calendar", data => {
      if (data.direction === "next") {
        this.next();
      } else {
        this.prev();
      }
    });
  },
  computed: {
    ...mapGetters([
      "getUserId",
      "getGLPGroup",
      "getPersonalToken",
      "getUserToken",
      "getSessionToken",
      "getUUID",
      "getUserProfileStatus",
      "getEcranModule"
    ]),
    calendarUrl() {
      //return `${PORTAL_CONFIG.FRONT_URL}/planning.php/?genical=1&uID=${this.getUserId}&gID=0&entities_id=${this.$route.params.id}&is_recursive=0&token=${this.getPersonalToken}`;
      return [
        `${PORTAL_CONFIG.FRONT_URL}/planning.php/?genical=1&uID=${this.getUserId}&gID=0&entities_id=${this.$route.params.id}&is_recursive=0&token=${this.getPersonalToken}`,
        `${PORTAL_CONFIG.FRONT_URL}/planning.php/?genical=1&uID=0&gID=${this.getGLPGroup}&entities_id=${this.$route.params.id}&is_recursive=0&token=${this.getPersonalToken}`
      ];
    },
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const date = moment(start.date).format("MMMM YYYY");
      const week = moment(start.date).week();

      return `${capitalize(
        date
      )} / <span style="color: red">Semaine ${week}</span>`;
    },
    smalltitle() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const date = moment(start.date).format("MM/YY");
      const week = moment(start.date).week();

      let smalltitle = `<h4>${capitalize(date)}</h4>`;

      return this.toggle_type === 2
        ? smalltitle
        : `${smalltitle}<h5 style="color: white">_</h5><h5 style="color: red">S${week}</h5>`;
    },
    qrCodeUrl() {
      const link = this.isDirectView
        ? `piresa.php?user=${this.$route.params.userToken}&entity=${this.$route.params.id}`
        : `resa.php?user=${this.$route.params.userToken}&entity=${this.$route.params.id}`;
      return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/${link}`;
    },
    allEvents() {
      return this.events.concat(this.icsEvents);
    },
    isDirectView() {
      return this.$route.meta.directView;
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    redirectLink() {
      return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/resa.php?user=${this.getUserToken}&entity=${this.$route.params.id}`;
    },
    sessionUUID() {
      return this.getEcranModule && this.getEcranModule.qr_dyn == 1
        ? this.getUUID
        : "pilot-immot-app";
    },
    uniqID() {
      return this.$route.params.id
        ? `${this.sessionUUID}-${this.$route.params.id}`
        : null;
    }
  },
  watch: {
    /*icsCalendar() {
      if (this.icsCalendar && this.icsCalendar.VCALENDAR) {
        const calendar = this.icsCalendar.VCALENDAR[0];
        const totalColors = this.icsColors.length;
        calendar.VEVENT.forEach((res, index) => {
          this.icsEvents.push({
            name: res.SUMMARY,
            start: this.formatDate(new Date(moment(res.DTSTART).format())),
            end: this.formatDate(new Date(moment(res.DTEND).format())),
            color: this.icsColors[index % totalColors]
          });
        });
      }
    },*/
    getUserProfileStatus() {
      if (this.getUserProfileStatus) {
        this.loadData();
      }
    },
    getPersonalToken() {
      if (this.getPersonalToken) {
        this.loadCalendar();
      }
    }
  }
};
</script>

<style>
.pl-1 {
  font-size: 0.9rem;
}
#calendar-view {
  position: absolute;
  top: 0;
  bottom: 110px;
  height: calc(100% - 96px);
}
#calendar-view #toolbar header {
  background-color: transparent;
  position: relative;
  z-index: 3;
}
.v-calendar-weekly__head {
  height: 50px;
  padding-top: 5px;
  background-color: #f7f7f7;
}
.v-calendar-weekly__head-weekday {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
